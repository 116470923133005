import React from 'react';

export default class Document extends React.Component {
  constructor() {
    super();
    this.state = {
      thumbnail: '',
    };
    this.documentIndex = 0;
    this._handleOpen = this._handleOpen.bind(this);
    this._handleShare = this._handleShare.bind(this);
    this._handleShareSubmit = this._handleShareSubmit.bind(this);
    this._handleDelete = this._handleDelete.bind(this);
    this._handleSign = this._handleSign.bind(this);
    this._handleDetails = this._handleDetails.bind(this);
  }

  componentDidMount() {
    this.documentIndex = this.props.parent.documents.length;
    this.props.parent.documents.push(this);
    var doclist_menu_el = document.querySelector(
      `#pm-doclist-list${this.props.id}`
    );
    var doclist_menu = new mdc.menu.MDCMenu(doclist_menu_el);
    var doclist_toggle = document.querySelector(
      `#pm-doclist-button${this.props.id}`
    );
    doclist_toggle.addEventListener('click', function () {
      doclist_menu.open = !doclist_menu.open;
    });
  }

  updateDocument(newProps) {
    var that = this;
    this.setState(newProps, function () {
      stopLoadingSpinner('thumb-image-' + that.props.id);
    });
  }

  render() {
    var history;
    let isShared = false;
    let sharedWithEntities = [];

    const doclistButton = `pm-doclist-button${this.props.id}`;
    const doclistList = `pm-doclist-list${this.props.id}`;
    const thumbStyle = {
      backgroundImage: `url('${this.state.thumbnail}')`,
    };
    const clickableStyle = {
      cursor: 'pointer',
    };

    if (this.props.sharedWithOwner) {
      sharedWithEntities.push('Owner');
    }
    if (this.props.sharedWithTenant) {
      sharedWithEntities.push('Tenant');
    }
    if (sharedWithEntities.length > 0) {
      isShared = true;
    }

    return (
      <div
        className="pm-flex pm-flex-align-center pm-doclist--row pm-doclist--doc"
        data-testing-id="document-row"
        data-document-name={this.props.name}
      >
        <div className="pm-doclist--thumb" id={'thumb-image-' + this.props.id}>
          <i className="mdi mdi-file-image"></i>
          <i className="mdi mdi-file-document"></i>
          <div
            id={'document_' + this.props.id + '_image'}
            className="pm-doclist--thumb-image"
            style={Object.assign(thumbStyle, clickableStyle)}
            onClick={this._handleOpen}
          ></div>
        </div>
        <div className="pm-doclist--desc pm-flex pm-flex-col pm-flex-justify-center pm-flex-grow">
          <div className="pm-flex pm-flex-5">
            <div
              className="pm-flex-col"
              style={{ width: '60%', marginBottom: '0' }}
            >
              <h4 onClick={this._handleOpen} style={clickableStyle}>
                {this.props.name}
              </h4>
            </div>
            <div
              className="pm-flex-col"
              style={{ width: '40%', marginBottom: '0', textAlign: 'end' }}
            >
              {isShared && (
                <p>{'Shared with: ' + sharedWithEntities.join(', ')}</p>
              )}
            </div>
          </div>
          <p>Created {this.props.created_at}</p>
          <p>{this.props.status}</p>
        </div>
        <div
          className={
            this.props.budgetsPage
              ? 'mdc-menu-surface--anchor'
              : 'mdc-menu-anchor'
          }
        >
          <button
            id={doclistButton}
            className="mdc-button mdc-button--dense mdc-button-icon mdc-button-row pm-text-color--blue1"
            data-mdc-auto-init="MDCRipple"
          >
            <i className="mdi mdi-dots-vertical"></i>
          </button>
          <div
            id={doclistList}
            className={
              'mdc-menu ' +
              (this.props.budgetsPage
                ? 'mdc-menu-surface'
                : 'mdc-menu--open-from-top-right')
            }
            tabIndex="-1"
          >
            <ul
              className="mdc-menu__items mdc-list"
              role="menu"
              aria-hidden="true"
            >
              {this.props.sign_option && (
                <li
                  className="mdc-list-item edit-panel"
                  role="menuitem"
                  tabIndex="0"
                  onClick={this._handleSign}
                >
                  Sign
                </li>
              )}
              <li
                className="mdc-list-item edit-panel"
                role="menuitem"
                tabIndex="0"
                onClick={this._handleOpen}
              >
                Open
              </li>
              <li
                className="mdc-list-item edit-panel"
                role="menuitem"
                tabIndex="0"
                onClick={this._handleShare}
              >
                Share
              </li>
              <li
                className="mdc-list-item edit-panel"
                role="menuitem"
                tabIndex="0"
                onClick={this._handleDetails}
              >
                Details
              </li>
              <li
                className="mdc-list-item edit-panel"
                role="menuitem"
                tabIndex="0"
                onClick={this._handleDelete}
              >
                Delete
              </li>
            </ul>
          </div>
        </div>

        {history}
      </div>
    );
  }

  _handleOpen() {
    var that = this;
    if (this.state.attachment_URL == '') window.open(this.state.attachment_URL);
    else {
      startLoadingSpinner('documents-modal');
      $.ajax({
        url: '/documents/' + that.props.id + '/view_pdf',
        type: 'GET',
        dataType: 'JSON',
        success: function (data) {
          if (data.html != null) {
            initDialog(data.html);
            stopLoadingSpinner('documents-modal');
          }
        },
      });
    }
  }

  _handleShare() {
    var that = this;
    startLoadingSpinner('documents-modal');
    $.ajax({
      url: '/documents/' + that.props.id + '/edit_document_redesign',
      type: 'GET',
      dataType: 'JSON',
      success: function (data) {
        initDialog(data.html, { acceptFunction: that._handleShareSubmit });
      },
      complete: function () {
        stopLoadingSpinner('documents-modal');
      },
    });
  }

  _handleShareSubmit() {
    var that = this;
    var $form = $('#edit-document-' + this.props.id);

    startLoadingSpinner('documents-modal');
    $.ajax({
      url: $form.attr('action'),
      type: $form.attr('method'),
      dataType: 'JSON',
      data: $form.serializeArray(),
      success: function (data) {
        if (data.errors) {
          alert(data.errors);
        } else {
          $('#mdc-dialog-edit-document').remove();
          showSnackbarMessage(
            'Document sharing settings updated successfully.',
            'pm-main-section'
          );
        }
      },
      complete: function () {
        stopLoadingSpinner('documents-modal');
        enableSubmit();
      },
    });
  }

  _handleSign() {
    startLoadingSpinner('documents-modal');
    var that = this;
    $.ajax({
      url: '/contract_lightbox_redesign/' + that.props.id,
      type: 'GET',
      success: function (data) {
        initDialog(data.html);
      },
      complete: function () {
        stopLoadingSpinner('documents-modal');
      },
    });
  }

  _handleDetails() {
    startLoadingSpinner('documents-modal');
    const that = this;
    const data = {};
    if (
      this.props.parent.props.parameters &&
      this.props.parent.props.parameters.lease_id
    ) {
      data['lease_id'] = this.props.parent.props.parameters.lease_id;
    }
    if (
      this.props.parent.props.parameters &&
      this.props.parent.props.parameters.company_id
    ) {
      data['company_id'] = this.props.parent.props.parameters.company_id;
    }
    if (
      this.props.parent.props.parameters &&
      this.props.parent.props.parameters.contact_id
    ) {
      data['contact_id'] = this.props.parent.props.parameters.contact_id;
    }
    $.ajax({
      url: `/documents/${that.props.id}/document_history`,
      data: data,
      type: 'GET',
      success: function (data) {
        initDialog(data.html);
        stopLoadingSpinner('documents-modal');
      },
    });
  }

  _handleDelete(e) {
    e.preventDefault();
    confirm(
      'Are you sure you want to completely remove this document?',
      function (ok) {
        if (ok) {
          this.props.onDelete(this.props.doc);
        }
      }.bind(this)
    );
  }
}
